<template>

<app-wrapper class="about">

	<app-wrapper :content="true" class="about-content" v-bgimage="$state.page.about_image">

		<h2>{{ $state.page.about_title }}</h2>

		<p>{{ $state.page.about_text }}</p>

		<app-more :to="{name: 'About'}" :text="$state.page.about_button" class="about-more" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.about {
	background-color: #112A45;
	padding: 64px 0px;
}

.is-mobile .about {
	padding: 40px 0px;
}

.about-content {
	background-repeat: no-repeat;
	background-position: 0px 0px;
	background-size: 458px 499px;
	min-height: 499px;
	padding-left: 520px;
	padding-top: 100px;
}

.is-tablet .about-content {
	background-size: 295px 321px;
	background-position: 0px 80px;
	padding-left: 0px;
	padding-top: 0px;
	min-height: 401px;
}

.is-mobile .about-content {
	background-size: 240px 262px;
	background-position: 50% 0px;
	padding-top: 280px!important;
}

.about-content h2 {
	color: #BBD9CD;
	text-align: left;
}

.is-tablet .about-content h2,
.is-mobile .about-content h2 {
	text-align: center;
}

.about-content p {
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
}

.is-tablet .about-content p {
	padding-left: 320px;
}

.is-mobile .about-content p {
	text-align: center;
	font-size: 14px;
}

.about-more {
	margin-top: 64px;
}

.is-mobile .about-more {
	margin-top: 40px;
}

.about-more >>> .more {
	color: #fff;
}

</style>
